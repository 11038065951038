import PropTypes from 'prop-types'

import { SortDirectionPropType, JobCategoryPropType } from '@/props'

export const propTypes = {
  className: PropTypes.string,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  setAddress: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  category: JobCategoryPropType.isRequired,
  setSortDirection: PropTypes.func.isRequired,
  sortDirection: SortDirectionPropType.isRequired,
  jobs: PropTypes.arrayOf(PropTypes.object).isRequired
}
