'use client'

import cn from 'clsx'
import React from 'react'
import Image from 'next/image'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'

import { type Job } from '@/types'
import * as C from '@/components'

interface HeroProps {
  jobs: Job[]
  newsletterEmail?: string
  newsletterEmailError?: string
  isNewsletterEmailSubmitted?: boolean
  isNewsletterEmailSubmitting?: boolean
  setNewsletterEmail?: (email: string) => void
  onSubmitNewsletterEmail?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const { NODE_ENV } = process.env

const Hero = (props: HeroProps): JSX.Element => {
  const {
    jobs,
    newsletterEmail,
    setNewsletterEmail,
    newsletterEmailError,
    onSubmitNewsletterEmail,
    isNewsletterEmailSubmitted,
    isNewsletterEmailSubmitting
  } = props

  const hasNewsletterEmailInputData =
    !_isUndefined(newsletterEmail) &&
    !_isUndefined(setNewsletterEmail) &&
    !_isUndefined(newsletterEmailError) &&
    !_isUndefined(onSubmitNewsletterEmail) &&
    !_isUndefined(isNewsletterEmailSubmitted) &&
    !_isUndefined(isNewsletterEmailSubmitting)

  return (
    <>
      <div
        className={cn(
          'bg-blue-900 dark:bg-blue-950 pt-16 pb-4 border-solid border-b',
          'border-b-blue-950 border-t border-t-blue-950 dark:border-t-blue-900',
          'dark:border-b-blue-900 px-4'
        )}
      >
        <div className="max-w-4xl flex-column lg:flex mx-auto">
          <div className="w-full lg:w-2/4">
            <h2
              className={cn(
                'text-2xl mb-8 lg:mb-0 lg:text-3xl font-bold tracking-wide text-white'
              )}
            >
              Browse over {jobs.length.toLocaleString()}+ remote jobs from
              around the world.
            </h2>
          </div>

          <div className="w-full lg:w-2/4 text-right flex justify-center items-center mb-8 lg:mb-0">
            <p className={cn('text-lg leading-8 text-white')}>
              Built with ♥️ in Bucharest, we curate the latest remote technology
              jobs from all over the internet with a focus on quality and
              diversity.
            </p>
          </div>
        </div>

        <div className="mb-8 lg:mb-0 max-w-4xl flex mx-auto">
          <Image
            className="mx-auto"
            src="/img/logo/blue_cover.png"
            alt="Global Gigs"
            width="400"
            height="300"
          />
        </div>
      </div>

      {hasNewsletterEmailInputData && (
        <C.NewsletterEmailInput
          newsletterEmail={newsletterEmail}
          setNewsletterEmail={setNewsletterEmail}
          newsletterEmailError={newsletterEmailError}
          onSubmitNewsletterEmail={onSubmitNewsletterEmail}
          isNewsletterEmailSubmitted={isNewsletterEmailSubmitted}
          isNewsletterEmailSubmitting={isNewsletterEmailSubmitting}
        />
      )}
    </>
  )
}

export default Hero
