'use client'

import React, { useState, useCallback, useEffect } from 'react'
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline'

import { Theme } from '@/types'

import { type ThemeToggleButtonProps } from './types'

const ThemeToggleButton: React.FC<ThemeToggleButtonProps> = (
  props: ThemeToggleButtonProps
): JSX.Element => {
  const { initialValue } = props
  const [theme, setTheme] = useState<Theme | null>(initialValue ?? null)

  useEffect(() => {
    if (theme !== null) {
      document.cookie = `theme=${theme};path=/;`

      const html = document.querySelector('html')

      if (html !== null) {
        html.classList.toggle('dark', theme === Theme.Dark)
      }
    } else {
      setTheme(
        window.matchMedia('(prefers-color-scheme: dark)').matches
          ? Theme.Dark
          : Theme.Light
      )
    }
  }, [theme, setTheme])

  const onToggleTheme = useCallback(() => {
    setTheme(theme === Theme.Dark ? Theme.Light : Theme.Dark)
  }, [theme, setTheme])

  return (
    <button type="button" onClick={onToggleTheme}>
      {theme === Theme.Dark ? (
        <MoonIcon
          aria-hidden="true"
          className="size-5 text-slate-800 dark:text-gray-300"
        />
      ) : (
        <SunIcon
          aria-hidden="true"
          className="size-5 text-slate-800 dark:text-gray-300"
        />
      )}
    </button>
  )
}

export default ThemeToggleButton
