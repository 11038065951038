import PropTypes from 'prop-types'
import _values from 'lodash/values'

import { JobPropType } from '@/props'

export const propTypes = {
  newsletterEmail: PropTypes.string,
  setNewsletterEmail: PropTypes.func,
  newsletterEmailError: PropTypes.string,
  onSubmitNewsletterEmail: PropTypes.func,
  isNewsletterEmailSubmitted: PropTypes.bool,
  isNewsletterEmailSubmitting: PropTypes.bool,
  jobs: PropTypes.arrayOf(JobPropType.isRequired).isRequired
}
