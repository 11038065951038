import PropTypes from 'prop-types'

import { JobPropType } from '@/props'

export const propTypes = {
  newsletterEmail: PropTypes.string,
  setNewsletterEmail: PropTypes.func,
  query: PropTypes.string.isRequired,
  onSubmitNewsletterEmail: PropTypes.func,
  isNewsletterEmailSubmitted: PropTypes.bool,
  isNewsletterEmailSubmitting: PropTypes.bool,
  newsletterEmailError: PropTypes.string,
  jobs: PropTypes.arrayOf(JobPropType.isRequired).isRequired
}
