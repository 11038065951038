'use client'

import React from 'react'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'
import { SpeedInsights } from '@vercel/speed-insights/next'
import { GoogleAnalytics } from '@next/third-parties/google'
import { Analytics as VercelAnalytics } from '@vercel/analytics/react'

import * as U from '@/utils'
import { COOKIES_ACCEPTED_SKEY } from '@/components/cookie_banner/const'

const NEXT_PUBLIC_GA_ID = 'G-06NRK0ZC59'

const Analytics = (): JSX.Element | null => {
  if (!U.isStorageAvailable(U.StorageType.LocalStorage)) {
    return null
  }

  const cookiesAccepted =
    typeof window === 'undefined' || _isUndefined(localStorage)
      ? false
      : (localStorage.getItem(COOKIES_ACCEPTED_SKEY) ?? 'false') === 'true'

  if (!cookiesAccepted) {
    return null
  }

  console.log('loading next.js analytics')

  return (
    <>
      {!_isEmpty(NEXT_PUBLIC_GA_ID) && (
        <GoogleAnalytics gaId={NEXT_PUBLIC_GA_ID ?? ''} />
      )}

      <SpeedInsights />
      <VercelAnalytics />
    </>
  )
}

export default Analytics
