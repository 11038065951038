import cn from 'clsx'
import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import _isEmpty from 'lodash/isEmpty'
import { MapPinIcon } from '@heroicons/react/24/outline'

import { propTypes } from './props'
import { type JobListItemProps } from './types'

const JobListItem: React.FC<JobListItemProps> = (
  props: JobListItemProps
): JSX.Element => {
  const { job } = props
  const {
    id,
    tags,
    title,
    address,
    schedule,
    salaryMin,
    salaryMax,
    companyName,
    companyLogoURL,
    salaryCurrency
  } = job

  return (
    <li className="mb-8 last:mb-0 lg:mb-4">
      <Link
        href={`/job/${id}`}
        className={cn(
          'dark:hover:bg-slate-800/50',
          'hover:cursor-pointer rounded-lg dark:bg-slate-800',
          'flex-column lg:flex items-center px-8 py-4 lg:border-solid',
          'lg:border lg:border-gray-200 hover:bg-slate-100 lg:dark:border-gray-600'
        )}
      >
        <Image
          width="100"
          height="100"
          alt={companyName}
          src={companyLogoURL}
          className={cn(
            'mx-auto w-32 h-32 lg:w-16 lg:h-16 lg:mr-8 lg:ml-0 rounded-full',
            'shrink-0'
          )}
        />

        <div className="shrink-0 items-center w-full lg:w-2/4">
          <p
            className={cn(
              'text-sm leading-6 mb-2 lg:mb-0 text-gray-600 mt-2 lg:mt-0',
              'text-center lg:text-left dark:text-gray-400 w-full lg:w-3/4'
            )}
          >
            {companyName}
          </p>

          <p
            className={cn(
              'font-semibold text-lg lg:text-md leading-6 dark:text-gray-200',
              'text-gray-900 mb-2 text-center lg:text-left lg:text-ellipsis',
              'lg:overflow-hidden lg:whitespace-nowrap w-full lg:w-3/4'
            )}
          >
            {title}
          </p>

          {(!_isEmpty(salaryCurrency) ||
            salaryMin !== null ||
            salaryMax !== null) &&
            (salaryMin !== null || salaryMax !== null) && (
              <div
                className={cn(
                  'flex justify-center lg:justify-start items-center'
                )}
              >
                {!_isEmpty(salaryCurrency) && (
                  <p className="mr-1 text-sm text-gray-900 dark:text-gray-100">
                    {salaryCurrency}
                  </p>
                )}

                {salaryMin !== null && salaryMin > 0 && (
                  <p className="mr-1 text-sm text-gray-900 dark:text-gray-100">
                    {salaryMin?.toLocaleString()}
                  </p>
                )}

                {salaryMin !== null &&
                  salaryMax !== null &&
                  salaryMin !== 0 &&
                  salaryMax !== 0 && (
                    <p className="mr-1 text-sm text-gray-900 dark:text-gray-100">
                      -
                    </p>
                  )}

                {salaryMax !== null && salaryMax > 0 && (
                  <p className="text-sm text-gray-900 dark:text-gray-100">
                    {salaryMax?.toLocaleString()}
                  </p>
                )}
              </div>
            )}
        </div>

        <div className="mt-8 lg:mt-0">
          {tags.length > 0 && (
            <ul
              className={cn(
                'lg:ml-8 mb-4 flex justify-center lg:justify-start'
              )}
            >
              {tags.slice(0, 3).map((tag, j: number) => (
                <li
                  key={j}
                  className={cn(
                    'text-gray-600 bg-blue-50 ring-blue-500/10',
                    'rounded-md whitespace-nowrap mt-0.5 px-1.5',
                    'py-0.5 text-xs font-medium ring-1 ring-inset',
                    'mr-4 last:mr-0 dark:bg-blue-900 dark:text-gray-400'
                  )}
                >
                  <p>{tag}</p>
                </li>
              ))}
            </ul>
          )}

          <div
            className={cn(
              'flex justify-center items-center lg:justify-start',
              'lg:items-start'
            )}
          >
            <p
              className={cn(
                'lg:ml-8 inline-block text-gray-600 bg-gray-50',
                'ring-gray-500/10 rounded-md whitespace-nowrap',
                'mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1',
                'ring-inset mr-4 last:mr-0 dark:bg-slate-700 dark:text-gray-400'
              )}
            >
              {schedule}
            </p>
          </div>
        </div>

        {!_isEmpty(address) && (
          <div
            className={cn(
              'mt-8 lg:mt-0 flex-column lg:flex lg:w-full justify-end'
            )}
          >
            <div className="lg:flex-column flex">
              <div
                className={cn(
                  'flex mb-2 lg:mr-2 lg:mb-0 lg:justify-end w-full',
                  'lg:w-auto justify-center'
                )}
              >
                <MapPinIcon
                  className={cn(
                    'h-5 w-5 text-gray-600 mr-2 mt-[2px] dark:text-gray-400'
                  )}
                />

                <p className="text-gray-600 dark:text-gray-400 lg:whitespace-nowrap lg:text-ellipsis lg:overflow-hidden">
                  {address}
                </p>
              </div>
            </div>
          </div>
        )}
      </Link>
    </li>
  )
}

JobListItem.propTypes = propTypes

export default JobListItem
