'use client'

import cn from 'clsx'
import Link from 'next/link'
import _isUndefined from 'lodash/isUndefined'
import React, { useEffect, useState, useCallback } from 'react'

import { COOKIES_ACCEPTED_SKEY } from './const'

const INITIAL_COOKIES_ACCEPTED =
  typeof window === 'undefined' || _isUndefined(localStorage)
    ? false
    : (localStorage.getItem(COOKIES_ACCEPTED_SKEY) ?? 'false') === 'true'

const CookieBanner = (): JSX.Element | null => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [isAccepted, setIsAccepted] = useState<boolean | null>(
    INITIAL_COOKIES_ACCEPTED === true ? true : null
  )

  const onSetIsAccepted = useCallback(
    (value: boolean): void => {
      if (typeof window !== 'undefined' && !_isUndefined(localStorage)) {
        localStorage.setItem(COOKIES_ACCEPTED_SKEY, value ? 'true' : 'false')
      }

      setIsAccepted(value)
    },
    [isAccepted, setIsAccepted]
  )

  const onAcceptClick = useCallback((): void => {
    if (isAccepted === true) {
      return
    }

    onSetIsAccepted(true)
    location.reload()
  }, [isAccepted, setIsAccepted])

  const onRejectClick = useCallback((): void => {
    if (isAccepted === false) {
      return
    }

    onSetIsAccepted(false)
    location.reload()
  }, [isAccepted, setIsAccepted])

  useEffect((): void => {
    if (isAccepted === null) {
      setIsVisible(true)
    }
  }, [isAccepted, setIsVisible])

  if (typeof window === 'undefined' || !isVisible) {
    return null
  }

  return (
    <div
      className={cn(
        'fixed inset-x-0 bottom-0 flex flex-col justify-between gap-x-8',
        'gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row',
        'md:items-center lg:px-8 dark:bg-slate-900 dark:ring-gray-300/10',
        'border-t border-solid border-t-gray-900/10 dark:border-t-gray-100/10'
      )}
    >
      <p
        className={cn(
          'max-w-5xl text-sm leading-6 text-gray-900 dark:text-gray-300'
        )}
      >
        This website uses cookies to remember you and your preferences. We
        recommend you accept them for an optimal experience. See our{' '}
        <Link
          href="/cookie-policy"
          className="font-semibold text-blue-600 dark:text-blue-500"
        >
          cookie policy
        </Link>
        .
      </p>

      <div className="flex flex-none items-center gap-x-5">
        <button
          type="button"
          onClick={onAcceptClick}
          className={cn(
            'rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold',
            'focus-visible:outline-2 focus-visible:outline-offset-2',
            'dark:hover:bg-gray-100 dark:focus-visible:outline-gray-100',
            'text-white shadow-sm hover:bg-gray-700 focus-visible:outline',
            'focus-visible:outline-gray-900 dark:bg-gray-300 dark:text-black'
          )}
        >
          Accept all
        </button>

        <button
          type="button"
          onClick={onRejectClick}
          className={cn(
            'text-sm font-semibold leading-6 text-gray-900 dark:text-gray-300'
          )}
        >
          Reject all
        </button>
      </div>
    </div>
  )
}

export default CookieBanner
