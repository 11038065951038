'use client'

import cn from 'clsx'
import React, { useCallback } from 'react'
import { MagnifyingGlassIcon, MapPinIcon } from '@heroicons/react/24/outline'
import { BarsArrowUpIcon, BarsArrowDownIcon } from '@heroicons/react/20/solid'

import * as C from '@/components'
import { InputType } from '@/components/input'
import { type DropdownOption, SortDirection, JobCategory } from '@/types'

import { propTypes } from './props'
import { type JobListFilterHeaderProps } from './types'

// TODO: Import individual components instead of * as C throughout the codebase

// TODO: Extract
const CATEGORY_OPTIONS: DropdownOption[] = Object.keys(JobCategory).map(
  (category: string): DropdownOption => ({
    label: category,
    value: JobCategory[category as keyof typeof JobCategory]
  })
)

const JobListFilterHeader: React.FC<JobListFilterHeaderProps> = (
  props: JobListFilterHeaderProps
): JSX.Element => {
  const {
    query,
    address,
    setQuery,
    category,
    className,
    setAddress,
    setCategory,
    sortDirection,
    setSortDirection
  } = props

  const onToggleSortDirection = useCallback((): void => {
    if (sortDirection === SortDirection.Ascending) {
      setSortDirection(SortDirection.Descending)
    } else {
      setSortDirection(SortDirection.Ascending)
    }
  }, [sortDirection, setSortDirection])

  const onCategoryChange = useCallback(
    (value: string): void => {
      const newCategory = JobCategory[value as keyof typeof JobCategory]

      setCategory(newCategory ?? JobCategory.All)
    },
    [setCategory]
  )

  // prettier-ignore
  return (
    <div className={className}>
      <div className="flex-column px-8 lg:flex">
        <C.Input
          id="search"
          value={query}
          label="Search"
          onChange={setQuery}
          type={InputType.Search}
          placeholder="Enter a query"
          className="mb-4 w-full lg:mb-0 lg:mr-4"
          icon={(
            <MagnifyingGlassIcon
              aria-hidden="true"
              className="size-5 text-gray-400"
            />
          )}
        />

        {/*
        <C.Input
          id="address"
          label="Address"
          value={address}
          type={InputType.Text}
          onChange={setAddress}
          placeholder="Enter your address..."
          className="mb-4 w-full lg:mb-0 lg:mr-4"
          icon={(
            <MapPinIcon
              aria-hidden="true"
              className="size-5 text-gray-400"
            />
          )}
        />

        <div className="flex">
          <C.Dropdown
            id="category"
            label="Category"
            value={category}
            options={CATEGORY_OPTIONS}
            onChange={onCategoryChange}
            className="w-3/4 lg:min-w-64"
          />

          <div className="ml-8 lg:ml-4">
            <button
              type="button"
              onClick={onToggleSortDirection}
              className={cn(
                'relative mt-8 -ml-px inline-flex items-center',
                'text-gray-900 ring-1 ring-inset ring-gray-300',
                'dark:hover:bg-slate-700 dark:hover:text-gray-200',
                'gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold',
                'hover:bg-gray-50 dark:text-gray-300 dark:ring-gray-600'
              )}
            >
              {sortDirection === SortDirection.Descending && (
                <BarsArrowUpIcon
                  aria-hidden="true"
                  className="-ml-0.5 size-5 text-gray-400"
                />
              )}

              {sortDirection === SortDirection.Ascending && (
                <BarsArrowDownIcon
                  aria-hidden="true"
                  className="-ml-0.5 size-5 text-gray-400"
                />
              )}

              Sort
            </button>
          </div>
        </div>
        */}
      </div>
    </div>
  )
}

JobListFilterHeader.propTypes = propTypes

export default JobListFilterHeader
