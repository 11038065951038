import PropTypes from 'prop-types'

import { Color, SortDirection, JobCategory, JobSchedule } from '@/types'

export const SortDirectionPropType = PropTypes.oneOf([
  SortDirection.Ascending,
  SortDirection.Descending
])

export const JobSchedulePropType = PropTypes.oneOf([
  JobSchedule.Contract,
  JobSchedule.FullTime,
  JobSchedule.PartTime,
  JobSchedule.Freelance,
  JobSchedule.Temporary,
  JobSchedule.Internship
])

export const JobCategoryPropType = PropTypes.oneOf([
  JobCategory.All,
  JobCategory.Design,
  JobCategory.DevOps,
  JobCategory.CustomerSupport,
  JobCategory.MobileProgramming,
  JobCategory.ProductManagement,
  JobCategory.BackendProgramming,
  JobCategory.FrontendProgramming,
  JobCategory.FullStackProgramming
])

export const JobPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  createdAt: PropTypes.number.isRequired,
  salaryMin: PropTypes.number.isRequired,
  salaryMax: PropTypes.number.isRequired,
  category: JobCategoryPropType.isRequired,
  companyName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  schedule: JobSchedulePropType.isRequired,
  companyWebsiteURL: PropTypes.string.isRequired,
  salaryCurrency: PropTypes.string.isRequired,
  companyLogoURL: PropTypes.string.isRequired,
  applicationURL: PropTypes.string.isRequired,
  companyDescription: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
})

/**
 * A utility prop type to be re-used in components that accept a color prop.
 */
export const ColorPropType = PropTypes.oneOf([
  Color.Red,
  Color.Sky,
  Color.Rose,
  Color.Gray,
  Color.Zinc,
  Color.Lime,
  Color.Teal,
  Color.Cyan,
  Color.Blue,
  Color.Pink,
  Color.White,
  Color.Black,
  Color.Slate,
  Color.Stone,
  Color.Amber,
  Color.Green,
  Color.Orange,
  Color.Yellow,
  Color.Indigo,
  Color.Violet,
  Color.Purple,
  Color.Neutral,
  Color.Emerald,
  Color.Fuchsia,
  Color.Transparent
])
