'use client'

import cn from 'clsx'
import _chunk from 'lodash/chunk'
import _isEmpty from 'lodash/isEmpty'
import React, { useMemo } from 'react'

import * as C from '@/components'
import { type Job } from '@/types'

import { propTypes } from './props'
import { type JobListProps } from './types'
import _isUndefined from 'lodash/isUndefined'

const SHOW_NEWSLETTER_EMAIL_INPUT_EVERY_N_JOBS = 15

const JobList: React.FC<JobListProps> = (props: JobListProps): JSX.Element => {
  const {
    jobs,
    query,
    newsletterEmail,
    setNewsletterEmail,
    onSubmitNewsletterEmail,
    newsletterEmailError,
    isNewsletterEmailSubmitted,
    isNewsletterEmailSubmitting
  } = props

  const filteredJobs = useMemo(
    () =>
      jobs.filter(
        ({ tags, title, description }) =>
          _isEmpty(query) ||
          title.toLowerCase().includes(query.toLowerCase()) ||
          description.toLowerCase().includes(query.toLowerCase()) ||
          typeof tags.find((t) => t.toLowerCase().includes(query)) !==
            'undefined'
      ),
    [query, jobs]
  )

  const jobChunks = _chunk(
    filteredJobs,
    SHOW_NEWSLETTER_EMAIL_INPUT_EVERY_N_JOBS
  )
  const hasNewsletterEmailInputData =
    !_isUndefined(newsletterEmail) &&
    !_isUndefined(setNewsletterEmail) &&
    !_isUndefined(onSubmitNewsletterEmail) &&
    !_isUndefined(isNewsletterEmailSubmitted) &&
    !_isUndefined(newsletterEmailError) &&
    !_isUndefined(isNewsletterEmailSubmitting)

  return (
    <div
      className={cn(
        'bg-white py-8 px-8 lg:px-12 rounded-lg shadow border',
        'border-solid border-gray-200 dark:bg-slate-900 dark:border-gray-700'
      )}
    >
      <h3
        className={cn(
          'text-md font-semibold leading-6 text-gray-900 mb-4',
          'dark:text-gray-200'
        )}
      >
        {filteredJobs.length} Found
      </h3>

      {jobChunks.map((jobChunk: Job[], i: number) => (
        <>
          <ul>
            {jobChunk.map((job: Job, j: number) => (
              <C.JobListItem key={`job-${j}`} job={job} />
            ))}
          </ul>

          {hasNewsletterEmailInputData && i < jobChunks.length - 1 && (
            <C.NewsletterEmailInput
              newsletterEmail={newsletterEmail}
              setNewsletterEmail={setNewsletterEmail}
              onSubmitNewsletterEmail={onSubmitNewsletterEmail}
              newsletterEmailError={newsletterEmailError}
              isNewsletterEmailSubmitted={isNewsletterEmailSubmitted}
              isNewsletterEmailSubmitting={isNewsletterEmailSubmitting}
            />
          )}
        </>
      ))}
    </div>
  )
}

JobList.propTypes = propTypes

export default JobList
