import cn from 'clsx'
import React from 'react'
import Link from 'next/link'

import * as C from '@/components'
import { NAV_LINKS } from '@/const'
import { InputType } from '@/components/input/types'

import { CATEGORIES_COLUMN_LINKS } from './const'

const Footer = (): JSX.Element => {
  return (
    <footer
      aria-labelledby="footer-heading"
      className={cn(
        'bg-white dark:bg-slate-950 border-t border-solid border-t-gray-300',
        'dark:border-t-gray-700'
      )}
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>

      <div className="mx-auto max-w-7xl px-6 py-8 lg:px-8 lg:py-16">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="gap-8 lg:grid lg:grid-cols-2 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3
                  className={cn(
                    'text-md lg:text-sm font-semibold leading-6 text-gray-900',
                    'text-center lg:text-left dark:text-gray-300'
                  )}
                >
                  Categories
                </h3>

                <ul
                  role="list"
                  className="mt-6 space-y-4 text-center lg:text-left"
                >
                  {NAV_LINKS.map(
                    ({ label, href }, i: number): JSX.Element => (
                      <li key={i}>
                        <Link
                          href={href}
                          className={cn(
                            'dark:hover:text-gray-300 text-sm leading-6',
                            'text-gray-500 hover:text-black dark:text-gray-400'
                          )}
                        >
                          {label}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
