'use client'

import cn from 'clsx'
import Link from 'next/link'
import { Dialog } from '@headlessui/react'
import { usePathname } from 'next/navigation'
import React, { useCallback, useState } from 'react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import * as C from '@/components'

import { NAV_LINKS } from '@/const'
import { type NavbarProps } from './types'

const Navbar: React.FC<NavbarProps> = (props: NavbarProps): JSX.Element => {
  const { initialThemeValue } = props

  const pathname = usePathname()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false)

  const onOpenMobileMenu = useCallback((): void => {
    setIsMobileMenuOpen(true)
  }, [setIsMobileMenuOpen])

  const onCloseMobileMenu = useCallback((): void => {
    setIsMobileMenuOpen(false)
  }, [setIsMobileMenuOpen])

  return (
    <header
      className={cn(
        'bg-white dark:bg-slate-950 text-black border-b border-solid',
        'border-b-slate-200 dark:border-b-slate-800 py-4 px-8'
      )}
    >
      <nav
        aria-label="Global"
        className={cn('mx-auto flex max-w-7xl items-center justify-between')}
      >
        <div className="flex w-full items-center">
          <C.Logo />

          <div className="hidden lg:flex lg:w-full lg:gap-x-2">
            {NAV_LINKS.map(
              ({ label, href }, i: number): JSX.Element => (
                <Link
                  key={i}
                  href={href}
                  className={cn(
                    'shrink-0 text-sm font-semibold px-4 py-2 rounded-lg',
                    {
                      'border border-solid border-blue-100': pathname === href,
                      'dark:border-blue-900 text-blue-700': pathname === href,
                      'dark:text-blue-500 hover:border-blue-700':
                        pathname === href,

                      'dark:hover:border-blue-700 dark:hover:text-blue-400':
                        pathname === href,

                      'text-slate-800 dark:text-gray-400': pathname !== href,
                      'hover:text-slate-600 hover:dark:text-gray-300':
                        pathname !== href
                    }
                  )}
                >
                  {label}
                </Link>
              )
            )}
          </div>

          <C.ThemeToggleButton initialValue={initialThemeValue} />
        </div>

        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={onOpenMobileMenu}
            className={cn(
              '-m-2.5 inline-flex items-center justify-center rounded-md',
              'p-2.5 text-gray-700'
            )}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="size-6" aria-hidden="true" />
          </button>
        </div>
      </nav>

      <Dialog
        as="div"
        className="lg:hidden"
        open={isMobileMenuOpen}
        onClose={setIsMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />

        <Dialog.Panel
          className={cn(
            'fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white',
            'px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'
          )}
        >
          <div className="flex items-center justify-between">
            <C.Logo />
            <button
              type="button"
              onClick={onCloseMobileMenu}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="size-6" aria-hidden="true" />
            </button>
          </div>

          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {NAV_LINKS.map(
                  ({ label, href }, i: number): JSX.Element => (
                    <Link
                      key={i}
                      href={href}
                      className={cn(
                        '-mx-3 block rounded-lg px-3 py-2 text-base',
                        'leading-7 text-gray-900 hover:bg-gray-50 font-semibold'
                      )}
                    >
                      {label}
                    </Link>
                  )
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}

export default Navbar
