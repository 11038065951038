'use client'

import cn from 'clsx'
import Link from 'next/link'
import _isUndefined from 'lodash/isUndefined'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React, { useState, useEffect, useCallback } from 'react'

import { IS_DISMISSED_SKEY } from './const'

const INITIAL_BANNER_IS_DISMISSED =
  typeof localStorage === 'undefined'
    ? true
    : (localStorage.getItem(IS_DISMISSED_SKEY) ?? 'false') === 'true'

const Banner = (): JSX.Element | null => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [isDismissed, setIsDismissed] = useState<boolean>(
    INITIAL_BANNER_IS_DISMISSED
  )
  const onSetIsDismissed = useCallback(
    (value: boolean): void => {
      if (typeof window === 'undefined' || !_isUndefined(localStorage)) {
        localStorage.setItem(IS_DISMISSED_SKEY, value ? 'true' : 'false')
      }

      setIsDismissed(value)
    },
    [isDismissed, setIsDismissed]
  )

  const onCloseClick = useCallback((): void => {
    if (!isDismissed) {
      onSetIsDismissed(true)
      location.reload()
    }
  }, [isDismissed, onSetIsDismissed])

  useEffect((): void => {
    if (!isDismissed) {
      setIsVisible(true)
    }
  }, [isDismissed, setIsVisible])

  if (typeof window === 'undefined') {
    return null
  }

  return (
    <div
      className={cn(
        'flex items-center gap-x-6 bg-blue-800 px-6 py-2.5 sm:px-3.5',
        'sm:before:flex-1 dark:bg-blue-400'
      )}
    >
      <p className="text-sm leading-6 text-white dark:text-black">
        <Link href="/post">
          <strong className="font-semibold">Beta Stage Offer</strong>
          <svg
            viewBox="0 0 2 2"
            aria-hidden="true"
            className="mx-2 inline size-0.5 fill-current"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          Post an Ad for <strong className="font-bold">Free</strong> until the
          1st of September 2024
        </Link>
      </p>

      <div className="flex flex-1 justify-end">
        <button
          type="button"
          onClick={onCloseClick}
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        >
          <span className="sr-only">Dismiss</span>

          <XMarkIcon
            aria-hidden="true"
            className="size-5 text-white dark:text-black"
          />
        </button>
      </div>
    </div>
  )
}

export default Banner
