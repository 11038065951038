'use client'

import _isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'

import * as C from '@/components'

import { propTypes } from './props'
import { type JobListFilterableProps } from './types'
import { JobCategory, SortDirection } from '@/types'

const JobListFilterable: React.FC<JobListFilterableProps> = (
  props: JobListFilterableProps
): JSX.Element => {
  const {
    jobs,
    newsletterEmail,
    setNewsletterEmail,
    onSubmitNewsletterEmail,
    newsletterEmailError,
    isNewsletterEmailSubmitted,
    isNewsletterEmailSubmitting
  } = props

  const [query, setQuery] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [category, setCategory] = useState<JobCategory>(JobCategory.All)
  const [sort, setSort] = useState<SortDirection>(SortDirection.Ascending)

  return (
    <C.CenteredContainer>
      <C.JobListFilterHeader
        query={query}
        className="mb-8"
        address={address}
        setQuery={setQuery}
        category={category}
        setAddress={setAddress}
        setCategory={setCategory}
        sortDirection={sort}
        setSortDirection={setSort}
      />

      <C.JobList
        jobs={jobs}
        query={query}
        newsletterEmail={newsletterEmail}
        setNewsletterEmail={setNewsletterEmail}
        onSubmitNewsletterEmail={onSubmitNewsletterEmail}
        newsletterEmailError={newsletterEmailError}
        isNewsletterEmailSubmitted={isNewsletterEmailSubmitted}
        isNewsletterEmailSubmitting={isNewsletterEmailSubmitting}
      />
    </C.CenteredContainer>
  )
}

JobListFilterable.propTypes = propTypes

export default JobListFilterable
