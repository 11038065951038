'use client'

// import cn from 'clsx'
import React from 'react'
// import _isEmpty from 'lodash/isEmpty'

// import { Color } from '@/types'
// import { Input, InputType } from '@/components'
// import Button, { ButtonSize, ButtonType } from '@/components/button'

export interface NewsletterEmailInputProps {
  newsletterEmail: string
  newsletterEmailError?: string
  isNewsletterEmailSubmitted: boolean
  isNewsletterEmailSubmitting: boolean
  setNewsletterEmail: (email: string) => void
  onSubmitNewsletterEmail: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const NewsletterEmailInput = (
  _: NewsletterEmailInputProps
): JSX.Element => {
  // const {
  //   newsletterEmail,
  //   setNewsletterEmail,
  //   onSubmitNewsletterEmail,
  //   newsletterEmailError,
  //   isNewsletterEmailSubmitted,
  //   isNewsletterEmailSubmitting
  // } = props

  return <></>

  //
  // return (
  //   <>
  //     {isNewsletterEmailSubmitting && (
  //       <div
  //         className={cn(
  //           'bg-blue-900 dark:bg-blue-950 py-8 border-solid border-b',
  //           'border-b-blue-950 border-t border-t-blue-950 dark:border-t-blue-900',
  //           'dark:border-b-blue-900'
  //         )}
  //       >
  //         <div className="px-6 sm:px-6 lg:px-8">
  //           <div className="mx-auto max-w-2xl text-center">
  //             <h2
  //               className={cn(
  //                 'text-2xl font-semibold tracking-tight text-white sm:text-4xl'
  //               )}
  //             >
  //               Submitting email...
  //             </h2>
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //
  //     {!isNewsletterEmailSubmitted && (
  //       <div className={cn('bg-blue-950 dark:bg-blue-300 py-4')}>
  //         <div className="px-6 sm:px-6 lg:px-8">
  //           <div className="mx-auto max-w-2xl text-center">
  //             <div
  //               className={cn(
  //                 'mx-auto my-4 max-w-2xl text-2xl leading-8 text-blue-800 flex justify-center'
  //               )}
  //             >
  //               {_isEmpty(newsletterEmailError) && (
  //                 <p className="text-lg shrink-0 mt-2 text-white font-semibold mr-8">
  //                   Subscribe for updates
  //                 </p>
  //               )}
  //
  //               <Input
  //                 type={InputType.Email}
  //                 value={newsletterEmail}
  //                 onChange={setNewsletterEmail}
  //                 placeholder={
  //                   _isEmpty(newsletterEmailError)
  //                     ? 'Enter your email address'
  //                     : newsletterEmailError
  //                 }
  //                 errorBorder={!_isEmpty(newsletterEmailError)}
  //                 disabled={isNewsletterEmailSubmitting}
  //                 className={cn(
  //                   'px-16 border min-w-32 shrink-0',
  //                   'rounded-md text-black text-xl mr-4'
  //                 )}
  //                 outline
  //                 large
  //               />
  //
  //               <Button
  //                 small
  //                 color={Color.Blue}
  //                 className="shrink-0"
  //                 size={ButtonSize.Small}
  //                 type={ButtonType.Button}
  //                 label="Notify me on launch!"
  //                 onClick={onSubmitNewsletterEmail}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //
  //     {isNewsletterEmailSubmitted && (
  //       <div
  //         className={cn(
  //           'bg-indigo-700 dark:bg-blue-400 py-8 border-solid border-b',
  //           'border-b-blue-450 border-t border-t-blue-450 dark:border-t-blue-300',
  //           'dark:border-b-blue-300'
  //         )}
  //       >
  //         <div className="px-6 sm:px-6 lg:px-8">
  //           <div className="mx-auto max-w-2xl text-center">
  //             <h2
  //               className={cn(
  //                 'text-2xl font-semibold tracking-tight text-white sm:text-xl'
  //               )}
  //             >
  //               You have been successfully subscribed to our newsletter!
  //             </h2>
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //   </>
  // )
}
