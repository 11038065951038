import cn from 'clsx'
import React from 'react'
import Link from 'next/link'
import { HomeIcon } from '@heroicons/react/20/solid'

import { type BreaddCrumbsProps } from './types'

const BreadCrumbs: React.FC<BreaddCrumbsProps> = (
  props: BreaddCrumbsProps
): JSX.Element => {
  const { breadCrumbs } = props

  return (
    <div
      className={cn(
        'bg-white dark:bg-slate-900 border-solid border-b border-b-gray-200',
        'dark:border-b-slate-800 px-4 lg:px-0'
      )}
    >
      <div className="mx-auto max-w-7xl">
        <nav className="flex" aria-label="Breadcrumb">
          <ol
            role="list"
            className={cn('mx-auto flex w-full max-w-screen-xl space-x-4')}
          >
            <li className="flex">
              <div className="flex items-center">
                <Link
                  href="/"
                  className={cn(
                    'text-gray-400 hover:text-gray-500 dark:text-gray-400',
                    'dark:hover:text-gray-300'
                  )}
                >
                  <HomeIcon aria-hidden="true" className="size-5 shrink-0" />

                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>

            {breadCrumbs.map(
              ({ name, href, current }, i: number): JSX.Element => (
                <li key={i} className="flex">
                  <div className="flex items-center">
                    <svg
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 24 44"
                      preserveAspectRatio="none"
                      className={cn(
                        'h-full w-6 flex-shrink-0 text-gray-200',
                        'dark:text-gray-700'
                      )}
                    >
                      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>

                    <Link
                      href={href}
                      aria-current={current && 'page'}
                      className={cn('ml-4 text-sm font-medium', {
                        'text-blue-600 dark:text-blue-500': current,
                        'text-gray-500 dark:text-gray-400': !current,
                        'hover:text-blue-800 dark:hover:text-blue-400': current,
                        'hover:text-gray-700 dark:hover:text-gray-200': !current
                      })}
                    >
                      {name}
                    </Link>
                  </div>
                </li>
              )
            )}
          </ol>
        </nav>
      </div>
    </div>
  )
}

export default BreadCrumbs
