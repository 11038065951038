import React from 'react'
import Link from 'next/link'
import cn from 'clsx'

import { type LogoProps } from './types'

const Logo: React.FC<LogoProps> = (props: LogoProps): JSX.Element => {
  const { className } = props

  return (
    <Link
      href="/"
      className={cn(
        className,
        'dark:border-r-blue-800',
        'pr-8 mr-8 border-solid border-r border-r-blue-400 shrink-0'
      )}
    >
      <span className="sr-only">Global Gigs</span>
      <h1 className="text-lg font-semibold text-slate-800 dark:text-gray-300">
        Global Gigs
      </h1>
    </Link>
  )
}

export default Logo
